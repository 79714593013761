import {
    Upendra,
    Akash,
    Pritam,
    AmitD,
    AmitY,
    Diksha,
    Kalyani,
    Jayant,
    Uma,
    Nandhita,
    Sagar,
    Puru,
    Harsh,
    Varsha,
    Reema,
    Ruchika,
    Sarfraz,
    Shivani,
    Anjali,
    Nikita,
    Bhumika,
    Priyanka,
    Dummy,
    Dummy1,
    Suhani
  } from "../static/img/TeamMembers/index";
import Male from "../static/img/img/male.png";
import Female from "../static/img/img/female.png";

const TeamData = [
    {
    id:1,
    image: Upendra,
    name:"Upendra Singh",
    designation:"Co-Founder & CEO Koderbox",
    konnectorx:"https://konnectorx.com/userDetails/f14dc8eb-3321-4c8f-b7d6-b9a3ff4f1160",
    linkedin:"https://www.linkedin.com/in/upendra-singh-ab41a14b/",
    gender:Male
},
    {
    id:2,
    image: Akash,
    name:"Akash Chauhan",
    designation:"Co-Founder & Developer",
    konnectorx:"",
    linkedin:"https://www.linkedin.com/in/chauhan-akash/",
    gender:Male
},
    {
    id:3,
    image: Pritam,
    name:"Pritam Gupta",
    designation:"Assistant Vice President (AVP)",
    konnectorx:"",
    // linkedin:"https://www.linkedin.com/in/pritam-gupta-b388321b3/",
    gender:Male
},
    {
    id:4,
    image: Nandhita,
    name:"Nanditha Kurup",
    designation:"Human Resource",
    konnectorx:"https://konnectorx.com/userDetails/125862ae-2fc1-49a3-a1d3-9c38d064ea0a",
    linkedin:"https://www.linkedin.com/in/nanditha-kurup-r-0a53771b8/",
    gender:Female
},
    {
    id:5,
    image: AmitD,
    name:"Amit Dalal",
    designation:"Fullstack Engineer",
    konnectorx:"",
    linkedin:"https://www.linkedin.com/in/amit-dalal-72046b216/",
    gender:Male
},
    {
    id:6,
    image: AmitY,
    name:"Amit Yadav",
    designation:"Backend Engineer",
    konnectorx:"https://konnectorx.com/userDetails/712ec353-583e-44a0-8a8f-8beca7c42d89",
    linkedin:"https://www.linkedin.com/in/yaduvanshi-amit-182608206/",
    gender:Male
},
    {
    id:7,
    image: Puru,
    name:"Puru Koli",
    designation:"Machine Learning Engineer",
    konnectorx:"",
    linkedin:"https://www.linkedin.com/in/puru-koli-3073861a4/",
    gender:Male
},
    {
    id:8,
    image: Sagar,
    name:"Sagar",
    designation:"Frontend Web Developer",
    konnectorx:"https://konnectorx.com/userDetails/166b1eab-ba05-4b53-acea-ae92cc5d4ba1",
    linkedin:"https://www.linkedin.com/in/sagar-6863961b0/",
    gender:Male
},
    {
    id:9,
    image: Harsh,
    name:"Harsh Singh",
    designation:"Graphics Designer",
    konnectorx:"",
    linkedin:"https://www.linkedin.com/in/harsh-singh-k-b91191259/",
    gender:Male
},
    {
    id:10,
    image: Jayant,
    name:"Jayant Sharma",
    designation:"Software Developer",
    konnectorx:"",
    linkedin:"https://www.linkedin.com/in/jayant-sharma-14482a1b7/",
    gender:Male
},
    {
    id:11,
    image: Kalyani,
    name:"Kalyani Kumari",
    designation:"HR Executive",
    konnectorx:"https://konnectorx.com/userDetails/3e4d15c3-2887-40bb-a4e7-56a334700f73",
    linkedin:"https://www.linkedin.com/in/jhakalyani/",
    gender:Female
},
    {
    id:12,
    image: Diksha,
    name:"Diksha Bhandari",
    designation:"Japanese Language Translator & Educator",
    konnectorx:"",
    linkedin:"",//"https://www.linkedin.com/in/diksha-bhandari-863124221/",
    gender:Female
},
    {
    id:13,
    image: Dummy,
    name:"Rishab Kumar",
    designation:"Japanese Language trainer",
    konnectorx:"",
    linkedin:"",
    gender:Male
},
    {
    id:14,
    image: Varsha,
    name:"Varsha Mishra",
    designation:"Japanese Language Translator & Educator",
    konnectorx:"",
    linkedin: "",//"https://www.linkedin.com/in/varsha-mishra-9b4817216/",
    gender:Female
},
    {
    id:15,
    image: Reema,
    name:"Reema Das",
    designation:"Digital Marketing Intern",
    konnectorx:"",
    linkedin:"https://www.linkedin.com/in/reema-das-681835104/",
    gender:Female
},
    {
    id:16,
    image: Ruchika,
    name:"Ruchika sharma",
    designation:"Japanese Language Translator & Educator",
    konnectorx:"",
    linkedin:"",//"https://www.linkedin.com/in/ruchika-sharma-b865b6236/",
    gender:Female
},
    {
    id:17,
    image: Sarfraz,
    name:"Sarfraz Patil",
    designation:"Digital Marketing Executive",
    konnectorx:"",
    linkedin:"https://www.linkedin.com/in/sarfraz-patel-206324227/",
    gender:Male
},
{
    id:19,
    image: Suhani,
    name:"Suhani Sharma",
    designation:"Digital Marketing Executive",
    konnectorx:"",
    linkedin:"",
    gender:Female
},
    {
    id:20,
    image: Shivani,
    name:"Shivani Mathur ",
    designation:"Japanese Language Translator & Educator",
    konnectorx:"",
    linkedin:"",//"https://www.linkedin.com/in/ruchika-sharma-b865b6236/",
    gender:Female
},
{
    id:21,
    image: Anjali,
    name:"Anjali Arora",
    designation:"Japanese Language Translator & Educator",
    konnectorx:"",
    linkedin:"",
    gender:Female
},
{
    id:22,
    image: Nikita,
    name:"Nikita  Joshi",
    designation:"Japanese Language Translator & Educator",
    konnectorx:"",
    linkedin:"",
    gender:Female
},
{
    id:23,
    image: Bhumika,
    name:"Bhumika Jain",
    designation:"Japanese Language Translator & Educator",
    konnectorx:"",
    linkedin:"",
    gender:Female
},
{
    id:24,
    image: Priyanka,
    name:"Priyanka Kalra",
    designation:"Japanese Language Translator & Educator",
    konnectorx:"",
    linkedin:"",//"https://www.linkedin.com/in/priyankakalra10/",
    gender:Female
},
{
    id:25,
    image: Uma,
    name:"Uma Sharma",
    designation:"Human Resource",
    konnectorx:"",
    linkedin:"https://www.linkedin.com/in/uma-sharma-7277361b0/",
    gender:Female
},
]

export default TeamData;