import React from 'react';
import Navbar from "../component/Navbar/Navbar";
import Quotes from '../static/img/quotes.svg';
import KonnectorxIcon from "../static/img/icons/konnectorx-icon.png";
import TeamData from "../lan/TeamData";


const Team = () => {
  return (
    <>
    <Navbar />
    <div className="team-container">
        <section className="header">
           <p><img className="translateY" src={Quotes} alt="" /><span className="quote-text"> Committed to Excellence, Committed to You. </span><img className="rotate-img" src={Quotes} alt="" /></p>
           <h2 className="highlighted-text">Meet Our <span className="light-text">Team -</span></h2>
        </section>
        <section id="team" className="team-content">
        {TeamData?.map((member) => {
                return (
                  <div className="team-item wow fadeInUp">
                    <div className="member">
                      <img
                        src={member.image}
                        className="img-fluid"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = member.gender;
                        }}
                        alt=""
                      />
                      <div className="member-info">
                        <div className="member-info-content" id={!member.linkedin&& "no-social"}>
                          <h4>{member.name}</h4>
                          <span>{member.designation}</span>
                          <div className="social">
                          { member.linkedin && (<a href={member.linkedin} target="_blank">
                              <i className="fa fa-linkedin"></i>
                            </a>)}
                            {member.konnectorx && (
                              <a href={member.konnectorx} target="_blank">
                                <img
                                  src={KonnectorxIcon}
                                  className="social-logo"
                                  alt=""
                                />
                              </a>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
        </section>
    </div>
    </>
  )
}

export default Team;